<template>
  <div class="page">
    <div class="thumb">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item,xh) in bglist" :key="xh">
          <div class="li" :class="{cur:xh===xz}" @click="choose(xh,item)">
            <img :src="item.pic" v-if="item.pic">
            <video :src="item.video" v-else></video>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
    </div>
    <div class="bg">
      <img :src="bginfo.pic" v-if="bginfo.pic" :class="whichclass">
      <video :class="whichclass" :src="bginfo.video" v-else preload="auto" autoplay loop muted
             @click.stop="openvo"></video>
      <div class="text">
        <span>{{bginfo.picname}}</span>
        <span>{{bginfo.picadd}}</span>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <router-link tag="a" :to="'/articles/'+articles.id">
          <div class="time">{{articles.mtime}}</div>
          <div class="title">{{articles.title}}</div>
          <div class="img"><img :src="articles.tpic"></div>
        </router-link>
      </div>
      <div class="top">
        <router-link tag="a" :to="'/lifes/'+lifes.id">
          <div class="time">{{lifes.mtime}}</div>
          <div class="title">{{lifes.title}}</div>
          <div class="img"><img :src="lifes.tpic"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
//import {list} from '@/api/api'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import "swiper/css/swiper.css";

export default {
    name: 'index',
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            sw: window.innerWidth,
            sh: window.innerHeight,
            bginfo: {},
            articles: {},
            lifes: {},
            bglist: [],
            xz: 0,
            whichclass: 'my-enter-active',
            swiperOptions: {
                //loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                slidesPerView: 4,
                spaceBetween: 5,
            }

        }
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        if (this.$store.state.articles.bgimg.length > 0) {
            this.bginfo = this.$store.state.articles.bgimg[0]
            this.bglist = this.$store.state.articles.bgimg
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'bgimg',
                perpage: 1000,
                where: ["xs=0"],
                order: ["id DESC"]
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                this.bginfo = rs[0]
                this.bglist = rs
            })

        }
        if (this.$store.state.articles.articles.length > 0) {
            this.articles = this.$store.state.articles.articles[0]
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'article',
                perpage: 1000,
                order: ["mtime DESC", "id DESC"]
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                this.articles = rs[0]
            })

        }
        if (this.$store.state.articles.lifes.length > 0) {
            this.lifes = this.$store.state.articles.lifes[0]
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'life',
                perpage: 1000,
                order: ["mtime DESC", "id DESC"]
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                this.lifes = rs[0]
            })

        }
    },
    mounted() {
        document.addEventListener('keyup', () => {
             if(this.$refs.mySwiper){
                 this.changeHandler()
             }
        })
    },
    methods: {
        openvo() {
            let target = event.currentTarget
            target.muted = false
        },
        changeHandler() {
            let max = this.bglist.length
            let keycode = event.code
            if (keycode === 'ArrowRight') {
                this.xz++
            }
            if (keycode === 'ArrowLeft') {
                this.xz--
            }
            if (this.xz < 0) {
                this.xz = max - 1
                this.$refs.mySwiper.swiperInstance.slideTo(max - 1);
            }
            if (this.xz > max - 1) {
                this.xz = 0
                this.$refs.mySwiper.swiperInstance.slideTo(0);
            }
            let acidex = this.$refs.mySwiper.swiperInstance.activeIndex
            //console.log(acidex)
            if (this.xz - acidex >= 4 && this.xz >= acidex) {
                this.$refs.mySwiper.swiperInstance.slideNext();
            }
            if (acidex - this.xz >= 1 && this.xz <= acidex) {
                this.$refs.mySwiper.swiperInstance.slidePrev();
            }
            if (keycode === 'ArrowRight'||keycode === 'ArrowLeft') {
                this.getBg(this.bglist[this.xz])
            }

        },
        choose(xh, item) {
            this.xz = xh
            this.getBg(item)
        },
        getBg(item) {
            this.whichclass = 'my-leave-active'
            this.bginfo = item
            let img = new Image()
            img.src = item.pic
            img.onload = () => {
                this.whichclass = 'my-enter-active'
            }

        }
    },
    computed: {}
}
</script>

